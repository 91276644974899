import React from 'react'
import PropTypes from 'prop-types'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  currentLocale: 'sv',
}

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_LOCALE': {
      return {
        ...state,
        currentLocale: action.payload,
      }
    }
    default:
      throw new Error('Bad action type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default GlobalContextProvider
